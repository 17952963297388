.branding-layout2-cards{
    border-radius: 20px;
    transition: 0.5s ease-in-out;
}

.branding-layout2-cards:hover{
    background-color: #9C40F2;
}

.branding-layout3{
    background-color: #0D0B0C;
    min-height: 100vh;
}

.branding-layout3-subdiv{
    background-image: url('../images/innerpage5/Group\ 1171275174.png');
    background-position-y: -10%;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (min-width:1024px) {
    .branding-layout-column{
        margin-top: 120px;
    }
}