@media screen and (min-width:1024px) {
    .home-text{
        font-size: 220px;
    }
    .icon-div-text{
        font-size: 30px;
    }
    .main-container{
        min-height: 100vh;
    }
}

/* Image Animations */

.rotating-image {
    animation: rotate 5s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  .moving-image {
    animation: moveSideToSide 3s ease-in-out infinite;
  }
  
  @keyframes moveSideToSide {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-20px); /* Adjust the value to control the range of movement */
    }
  }
  
