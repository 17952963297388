/* CRM */

.crm-product-card{
    background-color: #F0F0F0;
    padding: 20px;
    border-radius: 10px;
    transition: 0.2s ease-in-out;
}
.crm{
    background-image: url('../images/mtm_images/crm\ 1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.crm-product-card:hover{
    background: rgb(255,36,101);
  background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
  color: white;
}
.crm-product-card p:nth-child(2){
    height: 50px;
}

.crm-layout3-text{
    background: rgb(255,36,101);
background: linear-gradient(177deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
-webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        letter-spacing: 1px;
        font-weight: 500;
}

.crm-layout4-card1{
    background: rgb(255,36,101);
    background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
    height: 220px;
    border-radius: 15px;
}
.crm-layout4-card2{
    background-color: #FCC49C;
    height: 220px;
    border-radius: 15px;
}
.crm-layout4-card3{
    background-color: #FDDCC3;
    height: 220px;
    border-radius: 15px;
}
.crm-layout5-img{
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width:1024px) {
    .crm-layout5-imgdiv,.ocr-layout6-subdiv,.medical-subdiv,.medical-layout6{
        width: 60%;
    }
    .task-manager-subdiv{
        width: 70%;
    }
    .payroll-layout3-subdiv,.ocr-layout2-div,.background-verification-subdiv,.onlinetest-layout3-subdiv{
        width: 50%;
    }
    .payroll-layout5-div, .ticket-management-subdiv, .ocr-subdiv,.algo-layout7-subdiv,.medical-layout2-div{
        width: 50%;
    }
    .printlele-layout5-text{
        width: 50%;
        font-weight: 600;
    }
    .algo-text{
        font-size: 60px;
    }
}

@media screen and (max-width:767px) {
    .algo-text{
        font-size: 40px;
    }
}


/* Task Manager */
.task-manager-layout1{
    background-image: url('../images/products/BG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.task-maintext{
font-size: 60px;
font-weight: 600;
line-height: 70px;
}
.task-text-color{
    background: rgb(32,59,199);
background: linear-gradient(135deg, rgba(32,59,199,1) 0%, rgba(96,46,206,1) 17%, rgba(229,36,154,1) 33%, rgba(227,128,37,1) 50%, rgba(229,36,154,1) 67%, rgba(168,32,214,1) 83%, rgba(32,59,199,1) 100%);
-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
}
.task-manager-gardient-buttons{
    background: transparent;
    border: 5px solid;
    border-image: linear-gradient(135deg, rgba(32,59,199,1) 0%, rgba(96,46,206,1) 17%, rgba(229,36,154,1) 33%, rgba(227,128,37,1) 50%, rgba(229,36,154,1) 67%, rgba(168,32,214,1) 83%, rgba(32,59,199,1) 100%);
    border-image-slice: 1;
    padding: 10px 20px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50px;
}
.task-main-search{
    width: 50%;
    background-color: #F0F0F0;
}
.task-layout2-card{
    border-radius:12px;
    height: 150px;
    background-color: white;
}

/* Payroll Management */
.payroll-maindiv{
    background-image: url('../images/products/hero.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    border-radius: 10px;
}
.payroll-maintext{
    font-size: 44px;
    line-height: 60px;
    font-weight: 500;
}
.payroll-mainbtn, .ticket-management-btn{
    background: rgb(255,36,101);
    background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
    font-size: 13px;
}

.payroll-layout2{
    background-image: url('../images/products/Hero\ \(1\).png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 80vh;
    border-radius: 10px;
}

.payroll-layout2-card{
    background: rgb(79,79,79);
background: linear-gradient(135deg, rgba(79,79,79,1) 0%, rgba(79,79,79,0.5) 31%, rgba(79,79,79,0.5) 64%, rgba(79,79,79,1) 100%);
padding: 30px;
color: white;
border-radius: 15px;
min-height: 200px;
}
.payroll-layout2-card p{
    height: 80px;
}
.payroll-layout2-card button{
    color: white;
    padding-bottom: 4px;
}

.payroll-layout3-card div{
    background: rgb(90,44,218);
background: linear-gradient(180deg, rgba(90,44,218,1) 0%, rgba(48,24,116,1) 100%);
display: flex;
align-items: center;
border-radius: 10px;
margin-bottom: 20px;
}
.payroll-layout3-card{
    border-radius: 10px;
    min-height: 60vh;
}


/* Printlele */
.printlele-manager-layout1{
    background-image: url('../images/products/printleleHero.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.printlele-layout2-div{
    background-image: url('../images/products/Frame\ 38.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.printlele-layout5{
    background-image: url('../images/products/Frame\ 587.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
}

.ticket-layout5{
    background-image: url('../images/products/Frame\ 1138.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
    border-radius: 20px 20px 0px 0px;
}

.ticket-layout2-cards{
    min-height: 180px;
}

/* OCR */

.ocr-layout{
    background-image: url('../images/products/Hero\ Header.png');
    background-position-y: -100px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
}
.ocr-layout5{
    background-image: url('../images/products/Video.png');
    background-position-y: -200px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.ocr-layout6{
    background-image: url('../images/products/Frame\ 74.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 60vh;
}

/* Algo Trading */

.algo-trading-div{
    background-image: url('../images/products/Rectangle\ 10835.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    border-radius: 20px;
}
.algo-layout2-cards{
    background-color: #EBF5FD;
    border-radius: 10px;
    min-height: 300px;
}
.algo-layout7{
    background-image: url('../images/products/Rectangle\ 10835.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    border-radius: 20px;
}
.algo-contact-div{
    background-image: url('../images/mtm_images/algo\ trading\ Contact\ Us.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* Product Management */

.background-verification{
    background-image: url('../images/products/Background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 120vh;
}

/* Online Test */

.online-test-div{
    background-image: url('../images/products/Base.png');
    background-position-y: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.onlinetest-layout3{
    background-image: url('../images/products/Base\ \(1\).png');
    background-position-y: 20px;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 150vh;
}

.onlinetest-layout5{
    background-image: url('../images/products/Base\ \(1\).png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 60vh;
}

/* Medical Billing */

.medical-div{
    background-image: url('../images/products/Hero\ Page.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}
.medical-layout2-subdiv{
    background-image: url('../images/products/Frame\ 769.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
}

.medical-layout7{
    background-image: url('../images/products/CTA.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    min-height: 60vh;
}

/* HRMS */

.hrms{
    background-color: #e5e5fa;
    min-height: 110vh;
    position: relative;
}
.hrms2{
        background-image: url('../images/products/Illustration2.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 110vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
}
.hrms-text{
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;
}
.hrms-layout6-subdiv{
    width: 80%;
}

.hrms-layout7{
    background-color: #e5e5fa;
    min-height: 110vh;
    position: relative;
}
.hrms-layout7-img{
    left: 50%;
    transform: translateX(-50%);
}