.blogs-banner{
    height: 60vh;
    background-image: url('../images/blogs/Rectangle\ 44982.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blogs-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.blog-subdiv{
    transition: background 0.5s ease-in-out;
}
.blog-subdiv:hover{
background-color: #F5EBFF   ;
}