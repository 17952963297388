.software_consulting_cards{
    background-color: #121212;
    border-radius: 15px;
    color: white;
    padding: 24px;
    min-height: 310px;
}

.software-layout2{
    background-image: url('../images/innerpage5/Group\ 40.png');
    min-height: 210vh;
    background-position: center;
    /* background-position-y: 20px; */
    background-repeat: no-repeat;
    background-size: cover;
}

.software-layout2-text{
    background: rgb(255,36,101);
  background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
  border-radius: 20px 5px 20px 5px;
}

.software-layout2-card{
    border-radius: 20px;
    min-height: 450px;
    cursor: pointer;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.software-layout2-card div{
    border-radius: 0px 0px 20px 20px;
    border-bottom: 1px solid gray;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,1) 100%);
}
.text-color{
    color: #5A2CDA;
}
