.box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    background: #f0f0f0; /* Background color for the box */
    border: 2px solid #333; /* Border color for the box */
  }
  
  .text-container {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .box-div{
        transform: translate(0,50px);
  }
  
  .letter1,.letter2,.letter3,.letter4,.letter5,.letter6{
    /* font-size: 5rem; */
    opacity: 0;
    position: absolute;
    transition: transform 1s ease, opacity 1s ease;
  }

  .main-box{
    opacity: 0;
  }
  
  @keyframes expandBox {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: auto; /* Adjust to fit text size */
      height: auto; /* Adjust to fit text size */
    }
  }
  
  @keyframes comeAndAlign {
    0% {
      opacity: 0;
      transform: translate(0, 0);
      font-size: 20px;
    }
    50% {
      opacity: 1;
      transform: translate(0, -60px); /* Move up out of the box */
      font-size: 50px;
    }
    100% {
      opacity: 1;
      transform: translate(-220%, -10%); /* Align in the row */
      font-size: 220px;
    }
  }

  @keyframes comeAndAlign2 {
    0% {
      opacity: 0;
      transform: translate(0, 0);
      font-size: 20px;

    }
    50% {
      opacity: 0.5;
      transform: translate(0, -60px); /* Move up out of the box */
      font-size: 50px;

    }
    100% {
      opacity: 1;
      transform: translate(-170%, -10%); /* Align in the row */
      font-size: 220px;
    }
  }

  
  @keyframes comeAndAlign3 {
    0% {
      opacity: 0;
      transform: translate(0, 0);
      font-size: 20px;
    }
    50% {
      opacity: 0.5;
      transform: translate(0, -60px); /* Move up out of the box */
      font-size: 50px;
    }
    100% {
      opacity: 1;
      transform: translate(-40%, -10%); /* Align in the row */
      font-size: 220px;
    }
  }

  
  @keyframes comeAndAlign4 {
    0% {
      opacity: 0;
      transform: translate(0, 0);
      font-size: 20px;
    }
    50% {
      opacity: 0.5;
      transform: translate(0, -60px); /* Move up out of the box */
      font-size: 50px;
    }
    100% {
      opacity: 1;
      transform: translate(130%, -10%); /* Align in the row */
      font-size: 220px;
    }
  }

  
  @keyframes comeAndAlign5 {
    0% {
      opacity: 0;
      transform: translate(0, 0);
      font-size: 20px;
    }
    50% {
      opacity: 0.5;
      transform: translate(0, -60px); /* Move up out of the box */
      font-size: 50px;
    }
    100% {
      opacity: 1;
      transform: translate(140%, -10%); /* Align in the row */
      font-size: 220px;
    }
  }

  
  @keyframes comeAndAlign6 {
    0% {
      opacity: 0;
      transform: translate(0, 0);
      font-size: 20px;
    }
    50% {
      opacity: 0.5;
      transform: translate(0, -60px); /* Move up out of the box */
      font-size: 50px;
    }
    100% {
      opacity: 1;
      transform: translate(280%, -10%); /* Align in the row */
      font-size: 220px;
}
  }
  
  .text-container {
    animation: expandBox 1s forwards;
  }
  
  .letter1 {
    animation: comeAndAlign 0.6s ease-in forwards;
    animation-delay: 2.5s; /* Delay for first letter */
}

.letter2 {
    animation: comeAndAlign2 0.6s ease-in forwards;
    animation-delay: 3.5s; /* Delay for second letter */
}

.letter3 {
    animation: comeAndAlign3 0.6s ease-in forwards;
    animation-delay: 4.5s; /* Delay for third letter */
}

.letter4 {
    animation: comeAndAlign4 0.6s ease-in forwards;
    animation-delay: 5s; /* Delay for fourth letter */
}

.letter5 {
    animation: comeAndAlign5 0.6s ease-in forwards;
    animation-delay: 4s; /* Delay for fifth letter */
}

.letter6 {
    animation: comeAndAlign6 0.6s ease-in forwards;
    animation-delay: 3s; /* Delay for sixth letter */
}

.main-box{
  animation: comeAndAlignmain 2.5s ease-in forwards;
  animation-delay: 0.3s; /* Delay for sixth letter */
}

.main-box2{
  animation: comeAndAlignmain 2.5s ease-in forwards;
  animation-delay: 0.4s; /* Delay for sixth letter */
}

.main-box3{
  animation: comeAndAlignmain 2.5s ease-in forwards;
  animation-delay: 0.5s; /* Delay for sixth letter */
}

@keyframes comeAndAlignmain {
  0% {
    opacity: 0;
    transform: translate(0, 150vh);
  }
  50% {
    opacity: 1;
    transform: translate(0, 400%);
  }
  75% {
    opacity: 0.5;
    transform: translate(0, 400%);
  }
  100% {
    opacity: 0;
    transform: translate(0, 400%);
}
}

@keyframes comeAndAlignmain {
  0% {
    opacity: 0;
    transform: translate(0, 150vh);
  }
  50% {
    opacity: 1;
    transform: translate(0, 500%);
  }
  75% {
    opacity: 0.5;
    transform: translate(0, 500%);
  }
  100% {
    opacity: 0;
    transform: translate(0, 500%);
}
}

@keyframes comeAndAlignmain {
  0% {
    opacity: 0;
    transform: translate(0, 150vh);
  }
  50% {
    opacity: 1;
    transform: translate(0, 600%);
  }
  75% {
    opacity: 0.5;
    transform: translate(0, 600%);
  }
  100% {
    opacity: 0;
    transform: translate(0, 600%);
}
}
  
  .text-container {
    display: flex;
    justify-content: center;
  }
  
  .letter:nth-child(1) { --i: 0; }
  .letter:nth-child(2) { --i: 1; }
  .letter:nth-child(3) { --i: 2; }
  .letter:nth-child(4) { --i: 3; }
  

  .text-container2 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    z-index: 4;
    margin-top: 10%;
  }
  
  .letter2 {
    position: relative;
    opacity: 0;
  }
  
  .left:nth-child(1) {
    animation: moveM 0.8s forwards, moveMleft 0.5s forwards 1.4s;;
  }
  
  .left:nth-child(2) {
    animation: moveA 0.8s forwards, moveAright 0.5s forwards 1.4s;;
  }
  
  .rest {
    opacity: 0;
    animation: revealRest 0.5s forwards;
    animation-delay: 1.6s;
    position: absolute;
    /* left: -50%; */
    /* transform: translateX(-50%); */
  }
  
  @keyframes moveM {
    0% {
      transform: translateX(-100vw);
      opacity: 1;
    }
    100% {
      transform: translateX(50px);
      opacity: 1;
    }
  }
  @keyframes moveM {
    0% {
      transform: translateX(-100vw);
      opacity: 1;
    }
    100% {
      transform: translateX(20px);
      opacity: 1;
    }
  }

  @keyframes moveMleft {
    0% {
      transform: translateX(calc(20px )); /* Initial left position after collision */
    }
    100% {
      transform: translateX(calc(-60px )); /* Final left position */
    }
  }

  @keyframes moveAright {
    0% {
      transform: translateX(calc(20px )); /* Initial left position after collision */
    }
    100% {
      transform: translateX(calc(48px )); /* Final left position */
    }
  }
  
  @keyframes moveA {
    0% {
      transform: translateX(200vw);
      opacity: 1;
    }
    100% {
      transform: translateX(20px);
      opacity: 1;
    }
  }
  
  @keyframes revealRest {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }
    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
  