@font-face {
    font-family: 'aneisha';
    src: url('../../public/fonts/anesha.otf') format('opentype');

}

@font-face {
    font-family: 'strong';
    src: url('../../public/fonts/StrongYoung.otf') format('opentype');
}

@font-face {
    font-family: 'megira';
    src: url('../../public/fonts/Megira.otf') format('opentype');
}

.megira {
    font-family: 'megira';
}

.strong {
    font-family: 'strong';
}

.poppins {
    font-family: "Poppins", serif;
}

.anesha {
    font-family: 'aneisha';
}


@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.txtgrd {
    background: linear-gradient(90deg, 
        rgba(221, 52, 71, 1) 0%, 
        rgba(222, 203, 42, 1) 25%, 
        rgba(46, 155, 84, 1) 70%, 
        rgba(45, 51, 122, 1) 100%
    );
    background-size: 250% 100%; /* This is necessary for the movement effect */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    animation: gradientMove 10s linear infinite; /* Adjust duration and easing as needed */
}
