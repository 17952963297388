.inner-div1{
    background-image: url('../images/portrait-indian-happy-businessman-using-laptop-computer-office.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.inner-divs{
    height: 280px;
    font-size: 13px;
}

.inner-card1{
    /* transform: skewY(-5deg); */
    transform: rotate(-25deg);
    /* transform-origin: top left; */
}
.inner-card2{
    transform: rotate(-15deg);
}

.inner-card3{
    transform: rotate(0deg);
}
.inner-card4{
    transform: rotate(18deg);
}
.inner-card5{
    transform: rotate(26deg);
    margin-bottom: -40px;
}

.sector-btn{
    background-color: #CDF552;
    border: none;
}
.inner-text{
    font-size: 50px;
}

.inner-layout3{
    background-image: url('../images/div.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
}

.gradient-div1 {
    position: relative;
    overflow: hidden;
  }

  .orbit{
    position: absolute;
    top:50%;
    right: 10%;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    animation: orbit 15s linear infinite;
  }
  .orbit1 {
    background: radial-gradient(circle, rgba(124,193,165,0.9) 0%,rgba(89,138,123,0) 50%, rgba(25,45,44, 0) 100%);
  }

  .orbit2 {
background: radial-gradient(circle, rgba(255,245,184,0.9) 0%,rgba(255,234,181,0) 50%, rgba(25,45,44, 0) 100%);
  }

  .orbit3 {
background: radial-gradient(circle, rgba(120,120,120,0.4) 0%,rgba(255,234,181,0) 50%, rgba(25,45,44, 0) 100%);
  }
  
  @keyframes orbit {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translateX(200px);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translateX(200px);
    }
  }
  

.inner-layout4{
    background-image: url('../images/div2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
}

.inner-layout5{
    background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4963235294117647) 100%);
    height: 90vh;
}

.inner-layout6{
    background-image: url('../images/div3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
}

.inner-layout7{
    background-image: url('../images/div4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
}

.innerLayout4{
    background-image: url('../images/innerPage1/Group\ 1171275036.png');
    background-position: center;
    /* background-position-y: -120px; */
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.innerlayout4-cards{
    background: rgb(246,246,246);
background: linear-gradient(0deg, rgba(246,246,246,0.1) 0%, rgba(79,79,79,0.4) 100%);
border-radius: 20px;
padding: 30px;
min-height: 350px;
transition: 0.6s ease-in-out;
}
.inner-layout4-cards-p{
    background-color: #CDF552;
    transition: 0.6s ease-in-out;
}
.inner-layout4-cards-text{
    color: #CDF552;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    transition: 0.6s ease-in-out;
}
.inner-layout4-cards-subtext{
    color: #FCFCFC;
    font-size: 14px;
    transition: 0.6s ease-in-out;
}

.innerlayout4-cards:hover{
    background-color: #CDF552;
}
.innerlayout4-cards:hover .inner-layout4-cards-p{
    background-color: white;
}
.innerlayout4-cards:hover .inner-layout4-cards-text{
    color: black;
}
.innerlayout4-cards:hover .inner-layout4-cards-subtext{
    color: black;
}

.innerLayout5{
    background-image: url('../images/innerPage1/BlaizeAI\ -\ Artificial\ And\ Technology\ Hero\ Header\ UI\ Kit.png');
    background-position: center;
    background-position-y: -120px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.innerlayout5-cards{
    background: rgb(246,246,246);
    background: linear-gradient(153deg, rgba(246,246,246,0.6) 0%, rgba(79,79,79,0.3) 100%);
border-radius: 20px;
padding: 30px;
min-height: 200px;
transition: 0.4s ease-in-out;
}
.innerlayout5-cards:hover{
    transform: scale(0.95);
}

.inner-layout5-cards-text{
    color: white;
    font-size: 20px;
    transition: 0.5s ease-in-out;
    margin-top: 30px;
}

.inner-layout5-cards-subtext{
        background: linear-gradient(153deg, rgba(249,179,175,1) 0%, rgba(238,98,105,1) 23%, rgba(226,93,140,1) 44%, rgba(186,96,189,1) 67%, rgba(135,102,248,1) 85%, rgba(114,124,251,1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
}

@media screen and (min-width:1024px) {
    .inner4{
       /* padding-top: 60px;  */
    }
    .inner5{
        padding-top: 60px; 
     }
     .inner6{
        padding-top: 120px; 
     }
}

