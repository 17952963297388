.mobile-app-layout1{
    background-image: url('../images/innerpage4/Section\ 6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.mobile-app-layout2{
    background-image: url('../images/innerpage4/Section\ 3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
.mobile-app-layout4{
    background-image: url('../images/innerpage4/BG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
}
.mobile-app-layout2-div{
    min-height: 300px;
}