*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.montserrat{
    font-family: "Montserrat", sans-serif;
}

.rubik{
    font-family: "Rubik", sans-serif;
    font-weight: 100;
    font-size: 54px;
    line-height: 70px;
}
.rubik-blog{
    font-family: "Rubik", sans-serif;
}

.div4{
    background-image: url('../images/Group\ 1000003180.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 16vh;
}
.choose-us{
    background-color: #5A2CDA;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    transition: 0.6s ease;
}

.choose-us2{
    background-color: #D9D9D9;
    height: 80px;
    display: flex;
    color: black;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    color: black;
}
.works-text{
    font-size: 14px;
}

.layout3-div{
background-image: url('../images/Group\ 1000003115.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Footer */
.footer-div{
    min-height: 70vh;
}

.footer-links{
    margin-top: 30px;
    color: #62626F;
    font-size: 14px;
    cursor: pointer;
}
.footer-inputs::placeholder{
font-size: 14px;
font-family: "Rubik", sans-serif;
}
.footer-sublinks{
font-family: "Rubik", sans-serif;
color: #62626F;
font-size: 14px;
cursor: pointer;
}

.nav-text{
    font-size: 14px;
    cursor: pointer;
}

/* client says */
.client-review-div{
    background: rgb(23,24,29);
background: linear-gradient(131deg, rgba(23,24,29,1) 0%, rgba(52,55,64,1) 100%);
min-height: 100vh;
}

.client-main-text{
    font-size: 60px;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
}
.client-text{
background: linear-gradient(131deg, rgba(210,255,58,1) 0%, rgba(255,201,11,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.client-card{
    height: 420px;
    border-radius: 20px;
    background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,0.4) 0%, rgba(39,106,115,0.4) 100%);
transition: 0.6s ease;
}

.client-card2{
    height: 420px;
    border-radius: 20px;
    background-color: rgb(62, 62, 62,1);
}
.news-color{
    background-color: rgb(62, 62, 62);
    border-radius: 16px;
    padding: 10px;
    width: 66%;
    bottom: -10px;
    right: 0;
}

.news-container{
    background-image: url('../images/Group\ 1000003177.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 130vh;
}

.blogs-div{
    background-image: url('../images/Group\ 1000003196.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* ANIMATION 1 */
.first-div {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
    transition: transform 0.9s, opacity 0.9s;
    z-index: 1; /* Lower z-index */
  }

  .first-div1{
    background-color: #321663;
  }
  
  .second-div {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #e0e0e0;
    text-align: center;
    transition: transform 0.9s, opacity 0.9s;
    z-index: 2; /* Higher z-index than the first div */
  }
  
  .third-div {
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #d0d0d0;
    text-align: center;
    z-index: 3; /* Highest z-index */
  }

  .column {
    opacity: 1;
    transition: opacity 1s ease-out;
  }
  
  .column-right {
    transition-delay: 0.9s; /* Adjust this delay as needed */
  }
  
  .bg-transition {
    transition: background-color 1s ease-out, opacity 1s ease-out;
  }

  .icon-div{
    background: rgb(65,87,105);
background: radial-gradient(circle, rgba(65,87,105,1) 0%, rgba(23,22,22,1) 100%);
min-height: 100vh;
position: fixed;
width: 100%;
top: 0;
left: 0;
z-index: 3;
  }

  .icon-div-text{
    color: #D2FF3A;
    margin: 0;
    text-align: center;
    cursor: pointer;
  }
  .icon-div-subtext{
    color: #A3ABB0;
    font-size: 17px;
  }

  .first-div1{
    background: rgb(50,22,99);
background: linear-gradient(167deg, rgba(50,22,99,1) 0%, rgba(102,45,201,1) 100%);
  }
  .first-div2{
    background: rgb(240,241,31);
background: linear-gradient(167deg, rgba(240,241,31,1) 0%, rgba(254,255,95,1) 100%);
  }

  
  .second-div1{
    background-color: white;
  }
  .second-div2{
    background: rgb(21,65,174);
background: linear-gradient(201deg, rgba(21,65,174,1) 0%, rgba(16,46,122,1) 100%);
  }

  .third-div1{
    background: rgb(255,38,31);
background: linear-gradient(201deg, rgba(255,38,31,1) 0%, rgba(174,37,32,1) 100%);
  }

  .fourth-div1{
    background-color: #000000;
  }
  .fourth-div2{
    background: rgb(231,247,77);
background: linear-gradient(193deg, rgba(231,247,77,1) 0%, rgba(251,206,15,1) 100%);
  }

  .tilt-animation {
    animation: tilt 1.8s ease-in-out;
  }

  .client-card:hover{
    background-color: #5A2CDA;
  }
  
  @keyframes tilt {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-7deg);
    }
    /* 50% {
      transform: rotate(0deg);
    } */
    75% {
      transform: rotate(7deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  

  /* MOUSE ANIMATION */
  .mouse-animation {
    /* width: 100vw; */
    min-height: 90vh;
    position: relative;
    overflow: hidden;
  }
  
  .code-block-box {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #d0f64a;
    color: white;
    padding: 10px;
    /* border-radius: 8px; */
    font-family: monospace;
    font-size: 14px;
    pointer-events: none; /* So they don't block interaction */
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: fadeInOut 1s ease-out;
  }

  .square{
    transition: 0.5s ease-in;
  }
  
  /* Animation for boxes to appear and disappear */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .choose-div1{
    background-color: #EEEFF3;
    transition: 0.6s ease-in-out;
  }
  .choose-div1:hover{
    background-color: #5A2CDA;
  }

  .choose-head{
    transition: 0.6s ease;
  }
  .choose-div1:hover .choose-head,.choose-div1:hover .choose-text{
    color: white;
  }

  .choose-us{
    color: white;
    transition: 0.6s ease;
  }
  .choose-div1:hover .choose-us{
    color: black;
  }
  .choose-div1:hover .choose-us{
    background-color: #EEEFF3;
  }

  .choose-div2{
    background-color: #1e253a;
    transition: 0.6s ease;
  }
  .choose-div2:hover{
    background-color: #D9D9D9;
  }

  .choose-div2:hover ~ .choose-div1 {
    background-color: #5A2CDA; /* Or any color you prefer */
  }

  .choose-div2:hover .choose-head,.choose-div2:hover .choose-text{
    color: white;
  }

  .choose-div2:hover .choose-head2,.choose-div2:hover .choose-text2{
    color: black;
  }
  .choose-div2:hover .choose-us2{
    background-color: #1E253A;
    color: white;
  }

  .choose-text{
    font-size: 14px;
    transition: 0.6s ease;
    color: black;
}

.choose-text2{
  font-size: 14px;
  transition: 0.6s ease;
  color: white;
}

  .main-chooseus{
    position: relative;
  }
  .choose-us3{
    position: absolute;
    height: 80px;
    width: 86px;
    top: 7px;
    right: 3px;
    z-index: 1;
    background-color: rgb(168, 166, 166);
  }

  .choose-us4{
    position: absolute;
    height: 80px;
    width: 86px;
    top: 7px;
    right: 3px;
    z-index: 1;
    background-color: #d0f64a;
  }
  
  
  /* Main animation */

  .main-div8{
    top: 50%;
    transform: translateY(-50%);
  }
  .main-div1{
    border: 1px solid lightgray;
    height: 350px;
    width: 38%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 50% 50% 50%;
    position: relative;
    /* left: -40px; */
  }

  .positioned-text {
    position: absolute;
    right: 0px;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    /* padding: 10px; */
    white-space: nowrap;
  }

  .main-div2{
    border: 1px solid lightgray;
    height: 60%;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    /* left: -40px; */
  }

  .main-div3{
    border: 1px solid lightgray;
    height: 75%;
    width: 63%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    /* left: -40px; */
  }

  .main-div4{
    border: 1px solid lightgray;
    height: 95%;
    width:75%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    /* left: -40px; */
  }

  .main-div5{
    border: 1px solid lightgray;
    height: 125%;
    width:87%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    /* left: -40px; */
  }

  .main-div6{
    border: 1px solid lightgray;
    height:155%;
    width:98%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50% 50% 50% 50%;
    position: absolute;
    /* left: -40px; */
  }
  

  
/* Common styles for the divs */
.main-div1, .main-div2, .main-div3, .main-div4, .main-div5, .main-div6 {
   /* Vertically center the div */
  /* border: 1px solid lightgray; */
  opacity: 0;
  animation: slideInFromLeft 1.3s ease-out forwards;
}

/* Staggered animations */
.main-div6 {
  animation-delay: 2.5s;
}

.main-div5 {
  animation-delay: 2.6s;
}

.main-div4 {
  animation-delay: 2.7s;
}

.main-div3 {
  animation-delay: 2.8s;
}

.main-div2 {
  animation-delay: 2.9s;
}

.main-div1 {
  animation-delay: 3s;
}

/* Slide in animation from the left */
@keyframes slideInFromLeft {
  0% {
    transform: translate(-10vw, -50%);
     /* Start off-screen to the left */
    opacity: 0; /* Start invisible */
  }
  100% {
     transform: translate(0, -50%); 
    /* Move to its normal position */
    opacity: 1; /* Fully visible */
  }
}

/* Additional styling for the text in each div */
.positioned-text {
  /* padding: 20px; */
  /* font-size: 1.5rem; */
  color: black;
}

.home-text-div{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.home-text{
  /* color: #343434; */
  background-image: url('../images/text-img.png');
  font-family: "Montserrat", sans-serif;
  letter-spacing: -10px;
  font-weight: 700;
  line-height: 200px;
  position: relative;
  z-index: 3;
  text-align: center;
  /* font-size: 70px; */
  /* display: flex; */
}


.main-subdiv{
  top: 70%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  transition: 1s ease-in;
}

.faq-div{
  background-image: url('../images/Group\ 1171275039.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.faq-icon{
  background-color: rgba(221, 184, 255, 0.5);
}

.contact-us-layout{
  background: rgb(251,56,149);
background: linear-gradient(153deg, rgba(251,56,149,1) 0%, rgba(89,77,229,1) 100%);
min-height: 40vh;
}

.contact-inputs::placeholder{
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}
.contact-us-div{
  background-image: url('../images/innerpage4/Group\ 1171275142.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: rgb(231,240,255);
background: linear-gradient(173deg, rgba(231,240,255,1) 0%, rgba(240,240,255,1) 57%, rgba(255,255,255,1) 100%);
color: gray;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

