.inner-page2-div{
min-height: 100vh;
}

.inner-page2-subdiv{
    background-image: url('../images/innerpage2/Service\ Section.png');
    min-height: 90vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.page2-text{
    color: #9C40F2;
    text-align: center;
    letter-spacing: 2px;
}

.inner2-layout2{
    background-image: url('../images/innerpage2/Video\ Embed\ Section.png');
    min-height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.inner2-layout2 p:first-child{
    color: #00E0D9;
    letter-spacing: 2px;
}
.inner2-layout3{
    min-height: 100vh;
}

.inner2-layout3-card{
  background: rgb(255,36,101);
  background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
    min-height: 390px;
    border-radius: 15px;
}

.inner2-layout3-card div:first-child{
    background-image: url('../images/innerpage2/Rectangle\ 14.png');
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px 15px 0px 0px;
}

.video-background {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  } 
  
  