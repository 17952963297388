.inner3-text{
    background: rgb(255,36,101);
  background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
    font-weight: 600;
    font-size: 30px;
}

.inner-page3-card{
    min-height: 52vh;
}
.inner-page3-card p:nth-child(2){
    height: 16vh;
}

.inner-page4-svg{
height: 20px;
color: rgb(255,36,101);
}

.inner-page4-card{
    background-color: #2F2F2F;
    transition: background 0.8s ease-out, transform 0.4s ease-out;
    min-height: 180px;
  
}

.inner-page4-card:hover{
    background: rgb(255,36,101);
    background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
color: white;
transform: rotateY(15deg);
}

.inner-page4-card:hover .inner-page4-svg{
    color: white;
}

/* Website Development */
.website-layout3-card{
    background-color: #FFFF;
    min-height:56vh;
    transition: background 0.6s ease-in;
    padding: 30px;
}
.website-layout3-card:hover{
    background: rgb(255,36,101);
background: linear-gradient(186deg, rgba(255,36,101,1) 0%, rgba(59,53,145,1) 100%);
color: white;
}
.website_card_img{
    width: 40%;
    margin-bottom: 20px;
}