/* FloatingGradient.css */
.floating-gradient {
    width: 100%;
    height: 3vh;
    background: linear-gradient(90deg, #d2ff3a, #5b6cff, #1e2234);
    background-size: 300% 300%;
    animation: gradientAnimation 7s ease infinite;
  }

  .floating-gradient2 {
    width: 100%;
    height: 3vh;
    background: linear-gradient(90deg, #d2ff3a,#fdcc0d,#ffff,#5a2cda);
    background-size: 300% 300%;
    animation: gradientAnimation2 7s ease infinite;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 60% 60%;
    }
    75% {
        background-position: 100% 100%;
      }
    100% {
      background-position: 0% 0%;
    }
  }

  @keyframes gradientAnimation2 {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 50% 50%;
    }
    75% {
        background-position: 80% 80%;
      }
    100% {
      background-position: 0% 0%;
    }
  }
  

  @keyframes hangTilt {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(30deg);
    }
    /* 75% {
      transform: rotate(30deg);
    } */
    100% {
      transform: rotate(0deg);
    }
  }
  
  .hang-tilt-animation {
    display: inline-block;
    /* animation-delay: 6s; */
    animation: hangTilt 1.3s ease-out;
    transform-origin: left bottom;
  }
  .text{
    animation-delay: 13s;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
      /* left:4px;
      bottom: 4px; */
    }
    /* 50% {
      transform: rotate(90deg);
    } */
    100% {
      /* left:4px;
      bottom: 4px; */
      transform: rotate(180deg);
    }
  }
  
  .screw {
    display: inline-block;
    animation: rotate 1.4s ease-in-out;
    /* transform-origin: left bottom; */
  }

  .screw-img{
    animation-delay: 14.3s;
    left:4px;
    bottom: 4px;
  }
  .screw-img2{
    animation-delay: 14.3s;
    top: 4px;
    right: 4px;
  }

  .navbarclrgrd {
    background: linear-gradient(90deg, 
    rgba(221, 52, 71, 1) 0%, 
    rgba(222, 203, 42, 1) 25%, 
    rgba(46, 155, 84, 1) 70%, 
    rgba(45, 51, 122, 1) 100%); 
    animation: colorchange1 5s linear infinite;
    background-size: 250% 100%;
    filter: blur(30px);
  }
  
  @keyframes colorchange1 {
    0% {
      background-position: 0% 50%;
  }
  50%{
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
  }
  